<template>
  <div class="admin">
    <div class="sub-wrap">
      <div class="container">
        <header class="admin-header">
          <h1>News</h1>
        </header>
        <admin-nav></admin-nav>
        <div class="admin-body">
          <form @submit="update_article" class="form" method="post">
            <div class="form-wrap">
              <label for="title">Titel</label>
              <input v-model="article.title" class="form-input" type="text" name="title" id="title" />
            </div>

            <div class="form-wrap">
              <label for="body">Inhalt</label>
              <textarea v-model="article.body" rows="6" class="form-input" name="body" id="body"></textarea>
            </div>

            <div class="form-wrap">
              <label for="enabled">Aktiv?</label>
              <input type="checkbox" id="enabled" v-model="article.enabled" />
            </div>

            {{ errors }}

            <p style="margin-bottom: 0; text-align: right">
              <input type="submit" :value="$t('global.saveNow')" class="button button-ci button-round" />
            </p>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<style></style>

<script>
import axios from "axios";
import AdminNav from "@/components/admin_nav";

export default {
  name: "dashboard",
  components: {
    AdminNav,
  },
  data() {
    return {
      search_phrase: "",
      errors: [],
      article: {},
    };
  },
  methods: {
    get_data() {
      axios
        .get(process.env.VUE_APP_BASE_API + "/admin/articles/" + this.$route.params.uuid, { headers: { Authorization: this.$store.getters.getToken } })
        .then((response) => {
          this.article = response.data.article;
        });
    },
    update_article: function (e) {
      e.preventDefault();

      axios
        .patch(process.env.VUE_APP_BASE_API + "/admin/articles/" + this.$route.params.uuid, this.article, {
          headers: { Authorization: this.$store.getters.getToken },
        })
        .then((response) => {
          console.log(response);
          this.get_data();
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
  },
  mounted() {
    this.get_data();
  },
};
</script>
